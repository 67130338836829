<template>
    <div class="user-index">
        <div class="main-h1">Создание дела</div>
        <form class="card" v-on:submit.prevent>
            <div class="card-title">Новый иск</div>
            <div class="card-body">
                <div class="card-body-title">Название</div>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">
                            <div class="t">Произвольное название *</div>
                            <input v-model="claim.title"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Суммы</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Сумма иска *</div>
                            <input v-model="claim.amount"/>
                        </label>
                    </div>
                </div>
            </div>
            <button :disabled="!btnEnabled" class="btn green" @click="createClaim">Создать</button>
        </form>
    </div>
</template>

<script>
export default {
    name: "create",
    data() {
        return {
            claim:{
                implementer_id:0,
                claimant_id:0,
                arbiter_id:0,
                defendant_id:0,
                agent_id:0,
                stage_id:1,
                city_id:1,
            },
            users:{
                claimants:[],
                arbiters:[],
                agents:[],
                defendants:[],
                implementers:[],
            },
            stages:[],
            cities:[],
        }
    },
    methods: {
        createClaim() {
            this.$api.post(`claim_claimant`,this.claim).then(res => {
                if (res.data.success) {
                    this.claim = res.data.data;
                    this.$store.commit('showNotify',{title:'Сохранено',content:'Дело успешно создано.',type:'success'});
                    this.$router.push(`/panel/claim/${res.data.data.claim_id}/edit_claimant`);
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    },
    created() {
      this.getCities();
    },
    computed: {
        btnEnabled: function () {
            return this.claim.title && this.claim.amount;
        }
    }
}
</script>

<style scoped>

</style>